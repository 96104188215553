import '../styles/global.scss';
import '../styles/pages/solutions.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react'

import {
    sol1, sol2, sol3, sol4, sol5,
    BigLogoImage,
    LoadingGif
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import SolutionsMain from '../components/SolutionsMain';
import SolutionsQuestion from '../components/SolutionsQuestion';
import SolutionsTrialCorp from '../components/SolutionsTrialCorp';
import SolutionsFinal from '../components/SolutionsFinal';
import SolutionsTrial from '../components/SolutionsTrial';
import TargTrial from '../components/TargTrial';

type Props = {
    id: string,
    img: any,
    text: string
}

const TargSolutionsPage = ({id}: Props) => {
    // const [email, setEmail] = useState('');
    const [isLoading, setLoading] = useState(true);

    const onPageLoad = () => {
        setLoading(false);
    }

    const onSuccess = () => {
        console.log('success');
    }

    useEffect(() => {
        // storeEmail();

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={`solutionsSection`}>
                <div className={`solutionsWrap pb30 trial`}>
                    <TargTrial key={`trial-${id}`} id={id} onSuccess={onSuccess} />
                </div>
            </div>
        </>
    )
}

export default TargSolutionsPage;

export const Head: HeadFC = () => (
    <>
        <title>Solutions - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
